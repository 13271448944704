import React from 'react'
import Framework from '../components/Framework'
import MainMenu from '../components/MainMenu'
import {  Button, Tab, Menu, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ModalWrapper from '../components/ModalWrapper'
import CategoryForm from '../containers/CategoryForm'
import CategoryImagePositionForm from '../containers/CategoryImagePositionForm'
import ContentPane from '../components/ContentPane'

class CategoriesPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      categoriesFilterData: {},
      imagePositionsFilterData: {}
    }
  }

  onCategoriesFilterChange = (filterData) => {
    this.setState({
      categoriesFilterData: {...filterData}
    })
    this.props.loadCategories(filterData)
  }

  onImagePositionsFilterChange = (filterData) => {
    this.setState({
      imagePositionsFilterData: {...filterData}
    })
    this.props.loadImagePositions(filterData)
  }

  render() {
    return (
      <Framework>
        <div style={{
        display: 'flex'
        }}>
          <div>
            <MainMenu current='categories' />
          </div>
          <Tab activeIndex={this.props.panel == 'categories' ? 0 : 1} panes={[
            {
              menuItem: (
                <Menu.Item
                  key="categories"
                  as={Link}
                  to="/admin/categories"
                  name="categories">
                </Menu.Item>
              ),
              render: () => (<ContentPane
                onFilterChange={this.onCategoriesFilterChange}
                entities={this.props.categories}
                onMount={this.props.loadCategories}
                createForm={(closeModal) => (
                  <CategoryForm onSuccess={() => {
                    closeModal()
                    this.props.loadCategories(this.state.categoriesFilterData)
                  }}
                  />
                )}
                editForm={(closeModal, data) => (
                  <CategoryForm onSuccess={() => {
                    closeModal()
                    this.props.loadCategories(this.state.categoriesFilterData)
                  }} editMode={true} data={data}/>
                )}
                />)
            },
            {
              menuItem: (
                <Menu.Item
                  key="imagePositions"
                  as={Link}
                  to="/admin/categories/image-positions"
                  name="imagePositions">
                </Menu.Item>
              ),
              render: () => (<ContentPane
                onFilterChange={this.onImagePositionsFilterChange}
                entities={this.props.imagePositions}
                onMount={this.props.loadImagePositions}
                createForm={(closeModal) => (
                  <CategoryImagePositionForm onSuccess={() => {
                    closeModal()
                    this.props.loadImagePositions(this.state.imagePositionsFilterData)
                  }}
                  />
                )}
                editForm={(closeModal, data) => (
                  <CategoryImagePositionForm onSuccess={() => {
                    closeModal()
                    this.props.loadImagePositions(this.state.imagePositionsFilterData)
                  }} editMode={true} data={data}/>
                )}
                />)
            }
          ]} />
        </div>
      </Framework>
      )
  }
}

export default CategoriesPage
