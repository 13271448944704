import { connect } from 'react-redux'
import TagTypesList from '../components/TagTypesList'
import { loadTagTypes } from '../actions/tagType'

const mapStateToProps = (state) => ({
  loading: state.tagType.isLoadingTag,
  loadingFailed: state.tagType.hasTagLoadFailed,
  tagTypes: state.tagType.tagTypes
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadTagTypes: (params) => dispatch(loadTagTypes(params))
  }
}



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagTypesList)
