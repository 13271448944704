import { connect } from 'react-redux'
import WebsiteForm from '../components/WebsiteForm'
import { createWebsite, editWebsite, deleteWebsite, loadWebsiteImagePositions } from '../actions/website'
import { loadCategories } from '../actions/category'
import { loadTags } from '../actions/tag'

const mapStateToProps = (state) => ({
  isSubmitting: state.website.isSubmittingWebsite,
  hasSucceded: state.website.hasSubmittingWebsiteSucceded,
  categories: state.category.categories,
  tags: state.tag.tags,
  imagePositions: state.websiteImagePosition.websiteImagePositions
})

const mapDispatchToProps = (dispatch, props) => ({
  submit: data => dispatch(createWebsite(data)),
  edit: data => dispatch(editWebsite(data)),
  delete: data => dispatch(deleteWebsite(data)),
  loadCategories: params => dispatch(loadCategories({...params})),
  loadTags: params => dispatch(loadTags({...params})),
  loadImagePositions: data => dispatch(loadWebsiteImagePositions(data))



})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteForm)
