import { connect } from 'react-redux'
import TagTypesPage from '../pages/TagTypesPage'
import { loadTagTypes } from '../actions/tagType'


const mapDispatchToProps = (dispatch) => {
  return {
    loadTagTypes: (filterData) => dispatch(loadTagTypes(filterData))
  }
}



export default connect(
  null,
  mapDispatchToProps
)(TagTypesPage)
