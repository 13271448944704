import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class RichTextArea extends React.Component {

  constructor(props) {
    super(props)
    this.state = { text: '' }
  }

  static getDerivedStateFromProps = (props) => {
    return {
      text: props.value
    }
  }

  handleChange = (value) => {
    this.setState({ text: value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <ReactQuill
        defaultValue={this.state.text}
        onChange={this.handleChange}
      />
    )
  }
}

export default RichTextArea
