import axios from 'axios'
import history from '../history'
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const actionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED'
}


export const loginRequest = () => {
  return {
      type: actionTypes.LOGIN_REQUEST
  }
}

export const loginSuccess = (data) => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1)
  cookies.set('tk', data.token, {
    expires: tomorrow,
    path: '/'
  })
  return {
      type: actionTypes.LOGIN_SUCCESS,
      payload: data.token
  }
}

export const loginFailed = (error) => {
  return {
      type: actionTypes.LOGIN_FAILED,
      payload: error
  }
}

export const login = (data) => {
  return async dispatch => {
    let response
    dispatch(loginRequest())

    try {
      response = await axios.post('/api/login', data)

    } catch (e) {
      dispatch(loginFailed(e.message))
    }
    if (response) {
      dispatch(loginSuccess(response.data))
      history.push('/admin')
    }
  }

}
