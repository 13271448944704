import { actionTypes } from '../actions/tag'

export const defaultState = {
  isLoadingTags: false,
  hasTagLoadFailed: false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_TAGS :
        return {
          ...state,
          isLoadingTags: true,
          hasTagLoadFailed: false
        }
    case actionTypes.LOADING_TAGS_FAILED :
        return {
          ...state,
          isLoadingTags: false,
          hasTagLoadFailed: true
        }
    case actionTypes.LOADING_TAGS_SUCCESS :
        return {
          ...state,
          isLoadingTags: false,
          hasTagLoadFailed: false,
          hasSubmittingTagSucceded: false,
          tags: action.payload
        }
    case actionTypes.SUBMITTING_TAG :
        return {
          ...state,
          isSubmittingTag: true,
          hasSubmittingTagSucceded: false
        }
    case actionTypes.SUBMITTING_TAG_FAILED :
        return {
          ...state,
          isSubmittingTag: false,
          hasSubmittingTagSucceded: false
        }
    case actionTypes.SUBMITTING_TAG_SUCCESS :
        return {
          ...state,
          isSubmittingTag: false,
          hasSubmittingTagSucceded: true
        }
    default:
        return state
  }
}
