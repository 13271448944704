import { connect } from 'react-redux'
import ImagePositionForm from '../components/ImagePositionForm'
import { createWebsiteImagePosition, editWebsiteImagePosition, deleteWebsiteImagePosition } from '../actions/website'

const mapStateToProps = (state) => ({
  isSubmitting: state.websiteImagePosition.isSubmittingWebsiteImagePosition,
  hasSucceded: state.websiteImagePosition.hasSubmittingWebsiteImagePositionSucceded
})

const mapDispatchToProps = (dispatch, props) => ({
  submit: data => dispatch(createWebsiteImagePosition(data)),
  edit: data => dispatch(editWebsiteImagePosition(data)),
  delete: data => dispatch(deleteWebsiteImagePosition(data))

})

const WebsiteImagePositionForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagePositionForm)

export default WebsiteImagePositionForm
