import React from 'react'
import { Grid, Header, Segment } from 'semantic-ui-react'
import LoginForm from '../containers/LoginForm'

const LoginPage = () => (
  <Segment basic={true}>
  <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as='h2' color='teal' textAlign='center'>
         Log-in to your account
      </Header>
      <LoginForm />
    </Grid.Column>
  </Grid>
  </Segment>
)

export default LoginPage
