import React from 'react'
import { Button, Form, Message, Segment, Grid } from 'semantic-ui-react'
import { Formik } from 'formik'
import ErrorSummary from './ErrorSummary'
import moment from 'moment'

class ContentForm extends React.Component {

  onKeyPress = (event) => {
    if (event.target.type !== 'textarea' && event.which === 13) {
        event.preventDefault();
      }
  }


  componentDidUpdate() {
    if (this.resetForm && this.props.hasSucceded) {
      this.resetForm()
    }
    if (this.props.hasSucceded && this.props.onSuccess) {
      this.props.onSuccess()
    }
  }

  onClickDelete = () => {
    this.props.delete({id: this.props.data.id})
  }

  render() {
    return (
      <Segment loading={this.props.isSubmitting}>
        {this.props.hasSucceded && <Message positive>{`Content ${this.props.edit ? `modified` : `created`} succesfully!`}</Message>}
        <Formik
          initialValues={this.props.data || this.props.defaultData}
          validationSchema={this.props.validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { resetForm })=> {
            this.resetForm = resetForm
            if (this.props.editMode) {
              this.props.edit(values)
            } else {
              this.props.submit(values)
            }
          }}
        >
          {(formikProps) => (
            <Form error={Object.keys(formikProps.errors).length > 0} onKeyPress={this.onKeyPress} onSubmit={formikProps.handleSubmit}>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {this.props.editMode && this.props.data && <b>{`ID ${this.props.data.id}`}</b>}
                    {React.Children.map(this.props.children, child =>
                      React.cloneElement(child, {
                        value: formikProps.values[child.props.name],
                        onChange: (evt, {value}) => formikProps.setFieldValue(child.props.name, value),
                        onBlur: () => formikProps.setFieldTouched(child.props.name),
                        error: !!formikProps.errors[child.props.name],
                        ...child.props
                      })
                    )}
                    {this.props.editMode && this.props.data && <div>{`Created: ${moment(this.props.data.createdAt).format('DD/MM/YYYY HH:mm:ss')}`}</div>}
                    {this.props.editMode && this.props.data && <div>{`Updated: ${moment(this.props.data.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`}</div>}
                    {this.props.editMode && this.props.data && this.props.data.deletedAt && <div>{`Deleted: ${moment(this.props.data.deletedAt).format('DD/MM/YYYY HH:mm:ss')}`}</div>}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {Object.keys(formikProps.errors).length > 0 && <ErrorSummary errors={formikProps.errors} />}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {this.props.editMode && this.props.data && !this.props.data.deletedAt && <Button loading={this.props.isSubmitting} onClick={this.onClickDelete} secondary negative type="button">Delete</Button>}
                  </Grid.Column>
                  <Grid.Column>
                    {!this.props.data || (this.props.data && !this.props.data.deletedAt) ? <Button floated="right" loading={this.props.isSubmitting} primary type="submit">Submit</Button> : null}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Segment>
    )
  }
}


export default ContentForm
