import React from 'react'
import { Segment, Form, Tab, Menu } from 'semantic-ui-react'
import { Column, Table } from 'react-virtualized'
import 'react-virtualized/styles.css'
import moment from 'moment'
import ModalWrapper from '../components/ModalWrapper'
import {isEqual} from 'lodash'
import { Link } from 'react-router-dom'


export default class ContentList extends React.Component {
  width = 1550
  height = 700
  headerHeight = 40
  overscanRowCount = 2
  rowHeight = 40

  constructor(props) {
    super(props);
    this.state = {
      entities: undefined,
      currentRowData: undefined,
      filterData: {}
    };
  }

  componentDidUpdate() {
    if (!isEqual(this.props.entities, this.state.entities)) {
      this.setState({
        entities: this.props.entities
      })
    }
  }

  rowGetter  = ({index}) => {
    return this.state.entities[index]
  }

  timeCellRenderer = ({cellData}) =>  moment(cellData).format('DD/MM/YYYY HH:mm:ss')
  tableHeaderRenderer = ({className, columns, style}) => {
    return (<div className={className} role="row" style={style}>
      {columns}
    </div>)
  }

  onRowClick = ({rowData}) => {

    this.setState({
      modalOpen: true,
      currentRowData: rowData
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  onChangeShowDeleted = (event, {checked}) => {
    let filterData = {...this.state.filterData, showDeleted: checked}
    this.setState({
      filterData
    })
    this.props.onFilterChange && this.props.onFilterChange(filterData)
  }

  render() {
    return (
      <Segment compact={true} basic={true} loading={this.props.loading}>
        <Form>
          <Form.Checkbox toggle onChange={this.onChangeShowDeleted} label='Include deleted' />
        </Form>
        <ModalWrapper headerIcon='edit' headerContent={this.props.editModalWrapperHeader || 'Edit'}
          onClose={this.closeModal}
          open={this.state.modalOpen} render={
            (close) => this.props.editForm && this.props.editForm(close, this.state.currentRowData)
          }
        />
        {this.state.entities ? <Table
          headerRowRenderer={this.tableHeaderRenderer}
          ref="Table"
          headerHeight={this.headerHeight}
          height={this.height}
          overscanRowCount={this.overscanRowCount}
          rowHeight={this.rowHeight}
          rowGetter={this.rowGetter}
          rowCount={this.state.entities.length}
          onRowClick={this.onRowClick}
          rowClassName={'tableRow'}
          gridClassName={'tableGrid'}
          width={this.width}>
          <Column
            label="ID"
            dataKey="id"
            width={60}
          />
          <Column
            label="Name"
            dataKey="name"
            width={200}
          />
          <Column
            label="Created"
            dataKey="createdAt"
            width={200}
            cellRenderer={this.timeCellRenderer}
          />
          <Column
            label="Updated"
            dataKey="updatedAt"
            width={200}
            cellRenderer={this.timeCellRenderer}
          />
        </Table> : null}
      </Segment>
    )
  }

}
