import React from 'react'
import { Form, Dropdown } from 'semantic-ui-react'
import * as Yup from 'yup';
import ContentForm from './ContentForm'
import { debounce } from "debounce"


class TagForm extends React.Component {

  componentDidMount() {
    if (!this.props.tagTypes) {
      this.props.loadTagTypes({limit: 6})
    }
  }

  loadTagTypes = (e, {searchQuery}) => {
    this.props.loadTagTypes({term: searchQuery})
  }

  getTagTypes = () => {
    let tagTypes = []
    if (this.props.tagTypes) {
      tagTypes = this.props.tagTypes.map((tagType) => {
        return {
          value: tagType.id,
          key: tagType.id,
          text: tagType.name
        }
      })

    }

    return tagTypes
  }

  SelectComponent = (props) => (
    <Form.Field >
      <label>Tag type</label>
      <Dropdown
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        fluid
        search
        clearable
        selection
        selectOnBlur={false}
        placeholder="Start typing for a tag type"
        options={this.getTagTypes()}
        onSearchChange={debounce(this.loadTagTypes, 600)} />
    </Form.Field>
  )

  render() {
    return (
      <ContentForm
      defaultData={{
        name: '',
        tagTypeId: null
      }}
      {...this.props}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Required'),
        tagTypeId: Yup.number()
          .required('Required')
      })}
      >
        <Form.Input
          name="name"
          placeholder="The name of the tag"
          label="Name"
        />
        <this.SelectComponent name="tagTypeId" />

      </ContentForm>
    )
  }
}


export default TagForm
