import React from 'react'
import { DragDropContext } from "react-dnd";
import HTML5Backend from "@silvain-sayduck/react-dnd-html5-backend";
import ImagePool from './ImagePoolContainer'
import ImagePosition from './ImagePosition'

class ImageAssigner extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      images: new Map()
    }
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        images: this.props.value
      })
    }
  }

  onImageChange = (posId, images) => {
    const currentImages = new Map(this.state.images)
    if (images.length) {
      currentImages.set(posId, images)
    } else {
      currentImages.delete(posId)
    }
    this.setState({
      images: currentImages
    })
    if (this.props.onChange) {
      this.props.onChange(currentImages)
    }
  }

  render() {
    return (
      <div style={{width:'100%',display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
      <div style={{width:'50%'}}>
        <b>Positions</b>
        {this.props.positions.map(({name, width, height, id}, key) => (<ImagePosition value={this.props.value && this.props.value.get(id)} onChange={(images) => this.onImageChange(id, images)} key={key} name={`${name} - ${width}x${height}`} />))}
      </div>
      <div style={{width:'50%'}}>
        <b>Image Pool</b>
        <ImagePool/>
      </div>
      </div>
    )
  }
}

export default DragDropContext(HTML5Backend)(ImageAssigner)
