import axios from 'axios'
import Cookies from 'universal-cookie';
import { imageDataToMap, imageDataToArray } from '../lib/imageDataConverter'
const cookies = new Cookies();


export const actionTypes = {
  LOADING_WEBSITES_FAILED: 'LOADING_WEBSITES_FAILED',
  LOADING_WEBSITES_SUCCESS: 'LOADING_WEBSITES_SUCCESS',
  LOADING_WEBSITES: 'LOADING_WEBSITES',
  SUBMITTING_WEBSITE: 'SUBMITTING_WEBSITE',
  SUBMITTING_WEBSITE_FAILED: 'SUBMITTING_WEBSITE_FAILED',
  SUBMITTING_WEBSITE_SUCCESS: 'SUBMITTING_WEBSITE_SUCCESS',
  SUBMITTING_WEBSITE_IMAGE_POSITION: 'SUBMITTING_WEBSITE_IMAGE_POSITION',
  SUBMITTING_WEBSITE_IMAGE_POSITION_SUCCESS: 'SUBMITTING_WEBSITE_IMAGE_POSITION_SUCCESS',
  SUBMITTING_WEBSITE_IMAGE_POSITION_FAILED: 'SUBMITTING_WEBSITE_IMAGE_POSITION_FAILED',
  LOADING_WEBSITE_IMAGE_POSITIONS: 'LOADING_WEBSITE_IMAGE_POSITIONS',
  LOADING_WEBSITE_IMAGE_POSITIONS_SUCCESS: 'LOADING_WEBSITE_IMAGE_POSITIONS_SUCCESS',
  LOADING_WEBSITE_IMAGE_POSITIONS_FAILED: 'LOADING_WEBSITE_IMAGE_POSITIONS_FAILED'
}

export const submittingWebsiteSuccess = (data) => {
  return {
      type: actionTypes.SUBMITTING_WEBSITE_SUCCESS,
      payload: data
  }
}

export const submittingWebsiteFailed = () => {
  return {
      type: actionTypes.SUBMITTING_WEBSITE_FAILED
  }
}

export const submittingWebsite = () => {
  return {
      type: actionTypes.SUBMITTING_WEBSITE
  }
}

export const createWebsite = (params = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch((submittingWebsite()))
      const {websiteImagePosition} = getState()
      const data = {...params, images: imageDataToArray(params.images, websiteImagePosition.websiteImagePositions)}
      const response = await axios.post('/api/websites', data, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingWebsiteSuccess(response.data.websites))
    } catch (e) {
        dispatch(submittingWebsiteFailed())
    }
  }
}

export const deleteWebsite = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingWebsite()))
      const response = await axios.delete(`/api/websites/${params.id}`, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingWebsiteSuccess(response.data.websites))
    } catch (e) {
        dispatch(submittingWebsiteFailed())
    }
  }
}

export const editWebsite = (params = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch((submittingWebsite()))
      const {websiteImagePosition} = getState()
      const data = {...params, images: imageDataToArray(params.images, websiteImagePosition.websiteImagePositions)}
      const response = await axios.put(`/api/websites/${params.id}`, data, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingWebsiteSuccess(response.data.websites))
    } catch (e) {
        dispatch(submittingWebsiteFailed())
    }
  }
}

export const loadWebsitesSuccess = (data) => {
  return {
      type: actionTypes.LOADING_WEBSITES_SUCCESS,
      payload: data
  }
}

export const loadWebsitesFailed = () => {
  return {
      type: actionTypes.LOADING_WEBSITES_FAILED
  }
}

export const loadingWebsites = () => {
  return {
      type: actionTypes.LOADING_WEBSITES
  }
}

export const loadWebsites = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((loadingWebsites()))
      const { data } = await axios.get('/api/websites', {params})
      const transformedWebsites = data.websites.map((website) => {
        return {...website, images: imageDataToMap(website.images)}
      })
      dispatch(loadWebsitesSuccess(transformedWebsites))
    } catch (e) {
        dispatch(loadWebsitesFailed())
    }
  }
}

export const loadWebsiteImagePositionsSuccess = (data) => {
  return {
      type: actionTypes.LOADING_WEBSITE_IMAGE_POSITIONS_SUCCESS,
      payload: data
  }
}

export const loadingWebsiteImagePositionsFailed = () => {
  return {
      type: actionTypes.LOADING_WEBSITE_IMAGE_POSITIONS_FAILED
  }
}

export const loadingWebsiteImagePositions = () => {
  return {
      type: actionTypes.LOADING_WEBSITE_IMAGE_POSITIONS
  }
}

export const submittingWebsiteImagePositionSuccess = (data) => {
  return {
      type: actionTypes.SUBMITTING_WEBSITE_IMAGE_POSITION_SUCCESS,
      payload: data
  }
}

export const submittingWebsiteImagePositionFailed = () => {
  return {
      type: actionTypes.SUBMITTING_WEBSITE_IMAGE_POSITION_FAILED
  }
}

export const submittingWebsiteImagePosition = () => {
  return {
      type: actionTypes.SUBMITTING_WEBSITE_IMAGE_POSITION
  }
}


export const loadWebsiteImagePositions = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((loadingWebsiteImagePositions()))
      const response = await axios.get('/api/websiteImagePositions', {params})
      dispatch(loadWebsiteImagePositionsSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(loadingWebsiteImagePositionsFailed())
    }
  }
}

export const createWebsiteImagePosition = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingWebsiteImagePosition()))
      const response = await axios.post('/api/websiteImagePositions', params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingWebsiteImagePositionSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(submittingWebsiteImagePositionFailed())
    }
  }
}

export const deleteWebsiteImagePosition = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingWebsiteImagePosition()))
      const response = await axios.delete(`/api/websiteImagePositions/${params.id}`, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingWebsiteImagePositionSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(submittingWebsiteImagePositionFailed())
    }
  }
}

export const editWebsiteImagePosition = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingWebsiteImagePosition()))
      const response = await axios.put(`/api/websiteImagePositions/${params.id}`, params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingWebsiteImagePositionSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(submittingWebsiteImagePositionFailed())
    }
  }
}
