import React from 'react'
import { Form } from 'semantic-ui-react'
import * as Yup from 'yup';
import ContentForm from './ContentForm'

class ImagePositionForm extends React.Component {

  render() {
    return (
      <ContentForm
      defaultData={{
        name: ''
      }}
      {...this.props}
      onSuccess={() => {
        this.props.onSuccess()
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Required'),
        width: Yup.number().integer()
          .required('Required'),
        height: Yup.number().integer()
          .required('Required')
      })}
      >
        <Form.Input
          name="name"
          placeholder="The name of the image position"
          label="Name"
        />
        <Form.Input
          name="width"
          placeholder="The width of the image position"
          label="Width"
        />
        <Form.Input
          name="height"
          placeholder="The height of the image position"
          label="Height"
        />
      </ContentForm>
    )
  }
}


export default ImagePositionForm
