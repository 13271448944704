import React from 'react'
import Framework from '../components/Framework'
import MainMenu from '../components/MainMenu'
import {  Button, Tab, Menu, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ModalWrapper from '../components/ModalWrapper'
import WebsiteForm from '../containers/WebsiteForm'
import WebsiteImagePositionForm from '../containers/WebsiteImagePositionForm'
import ContentPane from '../components/ContentPane'

class WebsitesPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      websitesFilterData: {},
      imagePositionsFilterData: {}
    }
  }

  onWebsitesFilterChange = (filterData) => {
    this.setState({
      imagePositionsFilterData: {...filterData}
    })
    this.props.loadWebsites(filterData)
  }

  onImagePositionsFilterChange = (filterData) => {
    this.setState({
      imagePositionsFilterData: {...filterData}
    })
    this.props.loadImagePositions(filterData)
  }

  render() {
    return (
      <Framework>
        <div style={{
        display: 'flex'
        }}>
          <div>
            <MainMenu current='websites' />
          </div>
          <Tab activeIndex={this.props.panel == 'websites' ? 0 : 1} panes={[
            {
              menuItem: (
                <Menu.Item
                  key="websites"
                  as={Link}
                  to="/admin/websites"
                  name="websites">
                </Menu.Item>
              ),
              render: () => (<ContentPane
                onFilterChange={this.onWebsitesFilterChange}
                entities={this.props.websites}
                onMount={this.props.loadWebsites}
                createForm={(closeModal) => (
                  <WebsiteForm onSuccess={() => {
                    closeModal()
                    this.props.loadWebsites(this.state.websitesFilterData)
                  }}
                  />
                )}
                editForm={(closeModal, data) => (
                  <WebsiteForm onSuccess={() => {
                    closeModal()
                    this.props.loadWebsites(this.state.websitesFilterData)
                  }} editMode={true} data={data}/>
                )}
                />)
            },
            {
              menuItem: (
                <Menu.Item
                  key="imagePositions"
                  as={Link}
                  to="/admin/websites/image-positions"
                  name="imagePositions">
                </Menu.Item>
              ),
              render: () => (<ContentPane
                onFilterChange={this.onImagePositionsFilterChange}
                entities={this.props.imagePositions}
                onMount={this.props.loadImagePositions}
                createForm={(closeModal) => (
                  <WebsiteImagePositionForm onSuccess={() => {
                    closeModal()
                    this.props.loadImagePositions(this.state.imagePositionsFilterData)
                  }}
                  />
                )}
                editForm={(closeModal, data) => (
                  <WebsiteImagePositionForm onSuccess={() => {
                    closeModal()
                    this.props.loadImagePositions(this.state.imagePositionsFilterData)
                  }} editMode={true} data={data}/>
                )}
                />)
            }
          ]} />
        </div>
      </Framework>
      )
  }
}

export default WebsitesPage
