import { actionTypes } from './actions'

export const defaultState = {
  isLoadingImages: false,
  isUploading: false,
  images: [],
  fetching: false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_IMAGES :
        return {
          ...state,
          isLoadingImages: true,
          hasImageLoadFailed: false
        }
    case actionTypes.LOADING_IMAGES_FAILED :
        return {
          ...state,
          isLoadingImages: false,
          hasImageLoadFailed: true
        }
    case actionTypes.LOADING_IMAGES_SUCCESS :
        return {
          ...state,
          isLoadingImages: false,
          hasImageLoadFailed: false,
          images: action.payload
        }
    case actionTypes.SUBMITTING_IMAGE :
        return {
          ...state,
          isSubmittingImage: true,
          hasSubmittingImageSucceded: false
        }
    case actionTypes.SUBMITTING_IMAGE_FAILED :
        return {
          ...state,
          isSubmittingImage: false,
          hasSubmittingImageSucceded: false
        }
    case actionTypes.SUBMITTING_IMAGE_SUCCESS :
        return {
          ...state,
          isSubmittingImage: false,
          hasSubmittingImageSucceded: true
        }
    case actionTypes.UPLOADING_IMAGE :
        return {
          ...state,
          isUploading: true,
          hasUploadingSucceded: false
        }
    case actionTypes.UPLOADING_IMAGE_FAILED :
        return {
          ...state,
          isUploading: false,
          hasUploadingSucceded: false
        }
    case actionTypes.UPLOADING_IMAGE_SUCCESS :
        return {
          ...state,
          isUploading: false,
          hasUploadingSucceded: true,
          images: [action.payload, ...state.images]
        }
    case actionTypes.FETCHING :
        return {
          ...state,
          fetching: true,
          fetchedImage: undefined
        }
    case actionTypes.FETCHING_SUCCESS :
        return {
          ...state,
          fetching: false,
          fetchedImage: action.payload
        }
    case actionTypes.FETCHING_FAILED :
        return {
          ...state,
          fetching: false,
          fetchedImage: undefined
        }
    case actionTypes.FETCHED_IMAGE_CLEARED :
        return {
          ...state,
          fetchedImage: undefined
        }
    default:
        return state
  }
}
