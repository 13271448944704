import React from 'react'
import { Menu, Segment, Form, Tab, Icon, Select, Button } from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import AvatarEditor from 'react-avatar-editor'
import axios from 'axios'
import ImageCropper from './ImageCropper'
import ImageAssigner from './ImageAssigner'
import ImageFetcher from './ImageFetcherContainer'
import ImagePool from './ImagePoolContainer'

class ImageSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      image: undefined,
      fetchedImage: undefined
    }
  }

  async componentDidUpdate() {
    if (this.props.fetchedImage && !this.state.image) {
      const { data } = await axios.get('data:image/png;base64,'+this.props.fetchedImage, {
        responseType: 'blob',
      })
      const file = new File([data], 'fetchedImage.png', {type: 'image/png'});
      this.setState({
        fetchedImage: this.props.fetchedImage,
        image: file
      })
    }
  }

  onDrop = (acceptedFiles, rejectFiles) => {
    this.setState({ image: acceptedFiles[0] })
  }


  onCrop = (data) => {
    this.props.clearImage()
    this.props.uploadImage(data)
    this.setState({
      image: undefined,
      fetchedImage: undefined
    })
  }

  cancelCrop = () => {
    this.props.clearImage()
    this.setState({
      image: undefined,
      fetchedImage: undefined
    })
  }

  DropArea = () => {
    return (
      <Dropzone onDrop={this.onDrop}>
          {({getRootProps, getInputProps, isDragActive}) => {
            return (
              <div {...getRootProps()} style={{display:'flex',flexDirection: 'column', justifyContent: 'center', textAlign: 'center',width:'100%', height:`${this.state.image ? 'auto' : '150px'}`, cursor: 'pointer', backgroundColor:'#e0e0e0'}}>
                <input {...getInputProps()} />
                  {!this.props.isUploading && <div><Icon size='big' name='cloud upload'></Icon></div>}
                  {!this.props.isUploading && <div>Drop a file here or click to select</div>}
                  {this.props.isUploading && <div>Uploading  <Icon loading name='spinner' /></div>}
              </div>
            )
          }}
      </Dropzone>
    )
  }

  onImagePositionChange = (images) => {
    if (this.props.onChange) {

      this.props.onChange(images)
    }
  }

  render() {
      return (
      <Form.Field>
        <label>Image Selection</label>
        <Segment>
            <div style={{display: 'flex',flexWrap: 'wrap', alignContent:'stretch', minHeight:'500px'}} >
              {!this.state.image && <this.DropArea />}
              <div style={{width: '100%', display: this.state.image ? 'none' : 'block'}}><ImageFetcher url={this.props.fetchUrl} /></div>
              <div style={{width: '100%', display: this.state.image ? 'none' : 'block'}}><ImageAssigner onChange={this.onImagePositionChange} positions={this.props.positions} value={this.props.value}/></div>
              {this.state.image && <ImageCropper image={this.state.image} onCancel={this.cancelCrop} onCrop={this.onCrop} positions={this.props.positions} />}
            </div>
        </Segment>
      </Form.Field>
    )
  }
}
export default ImageSelector
