import { actionTypes } from '../actions/category'

export const defaultState = {
  isLoadingCategories: false,
  hasCategoryLoadFailed: false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_CATEGORIES :
        return {
          ...state,
          isLoadingCategories: true,
          hasCategoryLoadFailed: false
        }
    case actionTypes.LOADING_CATEGORIES_FAILED :
        return {
          ...state,
          isLoadingCategories: false,
          hasCategoryLoadFailed: true
        }
    case actionTypes.LOADING_CATEGORIES_SUCCESS :
        return {
          ...state,
          isLoadingCategories: false,
          hasCategoryLoadFailed: false,
          hasSubmittingCategorySucceded: false,
          categories: action.payload
        }
    case actionTypes.SUBMITTING_CATEGORY :
        return {
          ...state,
          isSubmittingCategory: true,
          hasSubmittingCategorySucceded: false
        }
    case actionTypes.SUBMITTING_CATEGORY_FAILED :
        return {
          ...state,
          isSubmittingCategory: false,
          hasSubmittingCategorySucceded: false
        }
    case actionTypes.SUBMITTING_CATEGORY_SUCCESS :
        return {
          ...state,
          isSubmittingCategory: false,
          hasSubmittingCategorySucceded: true
        }
    default:
        return state
  }
}
