import React, { Component } from 'react'
import { Header, Modal } from 'semantic-ui-react'

export default class ModalWrapper extends Component {

  handleClose = () => {
    this.props.onClose()
  }


  render() {
    return (
      <Modal
        size='large'
        open={this.props.open}
        onClose={this.props.onClose}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Header icon={this.props.headerIcon} content={this.props.headerContent} />
        <Modal.Content>
          {this.props.render(this.handleClose)}
        </Modal.Content>
      </Modal>
    )
  }
}
