import React from 'react'
import { Form } from 'semantic-ui-react'
import * as Yup from 'yup';
import ContentForm from './ContentForm'
import ImageSelector from '../containers/ImageSelector'
import RichTextArea from './RichTextArea'


class CategoryForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      imagePositions: []
    }
  }

  componentDidMount() {
    this.props.loadImagePositions()
  }

  componentDidUpdate() {
    if (this.state.imagePositions !== this.props.imagePositions) {
      this.setState({
        imagePositions: this.props.imagePositions
      })
    }
  }

  RichTextAreaWrapper = (props) => (
    <Form.Field>
      <label>{props.label}</label>
      <RichTextArea onChange={(value) => props.onChange(null, {value})} value={props.value} onBlur={props.onBlur}/>
    </Form.Field>
  )

  ImageSelectorWrapper = (props) => {
    return <ImageSelector
    onChange={(v) => {
      props.onChange(null, {value: v})
    }}
    name="images"
    value={props.value}
    positions={this.state.imagePositions} />
  }

  render() {
    return (
      <ContentForm
      defaultData={{
        name: '',
        shortDescription: '',
        description: ''
      }}
      {...this.props}
      create={(data) => {
        this.props.submit(data)
      }}
      edit={(data) => {
        this.props.edit(data)
      }}
      onSuccess={() => {
        this.props.onSuccess()
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Required')
      })}
      >
        <Form.Input
          name="name"
          placeholder="The name of the category"
          label="Name"
        />
        <this.RichTextAreaWrapper
          name="shortDescription"
          label="Short description"
        />

        <this.RichTextAreaWrapper
          name="description"
          label="Description"
        />
        <this.ImageSelectorWrapper name="images" positions={this.props.imagePositions || []}/>

      </ContentForm>
    )
  }
}


export default CategoryForm
