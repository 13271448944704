export const imageDataToArray = (images = [], imagePositions = []) => {
  const newImages = []
  images.forEach((images, positionId) => {
    const position = imagePositions.find((c) => c.id===positionId)
    for( const image of images ) {
      newImages.push({
        image,
        position
      })
    }
  })
  return newImages
}

export const imageDataToMap = (images) => {
  const newImages = new Map()
  for(const imagePos of images) {
    if (newImages.has(imagePos.position.id)) {
      const imgList = newImages.get(imagePos.position.id)
      imgList.push(imagePos.image)
      newImages.set(imagePos.position.id, imgList)
    } else {
      newImages.set(imagePos.position.id, [imagePos.image])
    }
  }
  return newImages
}
