import { connect } from 'react-redux'
import TagsPage from '../pages/TagsPage'
import { loadTags } from '../actions/tag'


const mapDispatchToProps = (dispatch) => {
  return {
    loadTags: (filterData) => dispatch(loadTags(filterData))
  }
}



export default connect(
  null,
  mapDispatchToProps
)(TagsPage)
