import { connect } from 'react-redux'
import ImageFetcher from './ImageFetcher'
import { fetchImageOg, fetchImageScr } from './actions'

const mapStateToProps = (state) => ({
  isLoading: state.image.fetching,
})

const mapDispatchToProps = (dispatch, props) => ({
  fetchImageScr: url => dispatch(fetchImageScr(url)),
  fetchImageOg: url => dispatch(fetchImageOg(url))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageFetcher)
