import { actionTypes } from '../actions/category'

export const defaultState = {
  isLoadingCategoryImagePositions: false,
  hasCategoryImagePositionLoadFailed: false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_CATEGORY_IMAGE_POSITIONS :
        return {
          ...state,
          isLoadingCategoryImagePositions: true,
          hasCategoryImagePositionLoadFailed: false
        }
    case actionTypes.LOADING_CATEGORY_IMAGE_POSITIONS_FAILED :
        return {
          ...state,
          isLoadingCategoryImagePositions: false,
          hasCategoryImagePositionLoadFailed: true
        }
    case actionTypes.LOADING_CATEGORY_IMAGE_POSITIONS_SUCCESS :
        return {
          ...state,
          isLoadingCategoryImagePositions: false,
          hasCategoryImagePositionLoadFailed: false,
          hasSubmittingCategoryImagePositionSucceded: false,
          categoryImagePositions: action.payload
        }
    case actionTypes.SUBMITTING_CATEGORY_IMAGE_POSITION :
        return {
          ...state,
          isSubmittingCategoryImagePosition: true,
          hasSubmittingCategoryImagePositionSucceded: false
        }
    case actionTypes.SUBMITTING_CATEGORY_IMAGE_POSITION_FAILED :
        return {
          ...state,
          isSubmittingCategoryImagePosition: false,
          hasSubmittingCategoryImagePositionSucceded: false
        }
    case actionTypes.SUBMITTING_CATEGORY_IMAGE_POSITION_SUCCESS :
        return {
          ...state,
          isSubmittingCategoryImagePosition: false,
          hasSubmittingCategoryImagePositionSucceded: true
        }
    default:
        return state
  }
}
