import { connect } from 'react-redux'
import ImagePositionForm from '../components/ImagePositionForm'
import { createCategoryImagePosition, editCategoryImagePosition, deleteCategoryImagePosition } from '../actions/category'

const mapStateToProps = (state) => ({
  isSubmitting: state.categoryImagePosition.isSubmittingCategoryImagePosition,
  hasSucceded: state.categoryImagePosition.hasSubmittingCategoryImagePositionSucceded
})

const mapDispatchToProps = (dispatch, props) => ({
  submit: data => dispatch(createCategoryImagePosition(data)),
  edit: data => dispatch(editCategoryImagePosition(data)),
  delete: data => dispatch(deleteCategoryImagePosition(data))

})
const CategoryImagePositionForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagePositionForm)

export default CategoryImagePositionForm
