import React from 'react'
import { Message } from 'semantic-ui-react'

const getErrorList = (errorList) => {
  let list = []
  for (const [name, error] of Object.entries(errorList)) {
    list.push(<li key={name}>{`${name}: ${error}`}</li>)
  }
  return (<ul>
    { list }
  </ul>)
}

const ErrorSummary = (props) => (
  <Message
    error
    header='Please fix the following errors'
    content={getErrorList(props.errors)}
  />
)

export default ErrorSummary
