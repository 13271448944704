import React, { Component } from 'react';
import './App.css';
import WebsitesPage from './containers/WebsitesPage'
import CategoriesPage from './containers/CategoriesPage'
import TagsPage from './containers/TagsPage'
import TagTypesPage from './containers/TagTypesPage'
import LoginPage from './pages/LoginPage'
import PrivateRoute from './containers/PrivateRoute'
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import history from './history'
import * as website from './reducers/website'
import * as login from './reducers/login'
import * as category from './reducers/category'
import * as tag from './reducers/tag'
import * as tagType from './reducers/tagType'
import * as categoryImagePosition from './reducers/categoryImagePosition'
import * as websiteImagePosition from './reducers/websiteImagePosition'
import * as image from './components/ImageSelector/reducer'


const rootReducer = combineReducers({
  website: website.rootReducer,
  login: login.rootReducer,
  category: category.rootReducer,
  tag: tag.rootReducer,
  tagType: tagType.rootReducer,
  image: image.rootReducer,
  categoryImagePosition: categoryImagePosition.rootReducer,
  websiteImagePosition: websiteImagePosition.rootReducer
})

const defaultState = {
  website: website.defaultState,
  login: login.defaultState,
  category: category.defaultState,
  tag: tag.defaultState,
  tagType: tagType.defaultState,
  image: image.defaultState,
  categoryImagePosition: categoryImagePosition.defaultState,
  websiteImagePosition: websiteImagePosition.defaultState
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  defaultState,
  composeEnhancers(
    applyMiddleware(thunkMiddleware)
  )
)

class App extends Component {

  render() {
    return (
        <Provider store={store}>
          <Router history={history}>
            <Switch>
                <Route exact path="/login" component={LoginPage} />
                <PrivateRoute exact path="/" component={() => <WebsitesPage panel='websites'/>} />
                <PrivateRoute exact path="/admin" component={() => <WebsitesPage panel='websites'/>} />
                <PrivateRoute exact path="/admin/websites" component={() => <WebsitesPage panel='websites'/>} />
                <PrivateRoute exact path="/admin/websites/image-positions" component={() => <WebsitesPage panel='imagePositions'/>} />
                <PrivateRoute exact path="/admin/categories" component={() => <CategoriesPage panel='categories'/>} />
                <PrivateRoute exact path="/admin/categories/image-positions" component={() => <CategoriesPage panel='imagePositions'/>} />
                <PrivateRoute exact path="/admin/tags" component={TagsPage} />
                <PrivateRoute exact path="/admin/tagTypes" component={TagTypesPage} />
            </Switch>
          </Router>
        </Provider>
    );
  }
}

export default App;
