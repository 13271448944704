import React from 'react'
import { Menu, Segment, Form, Tab, Icon, Select, Button } from 'semantic-ui-react'
import classNames from 'classnames'
import AvatarEditor from 'react-avatar-editor'
import axios from 'axios'

class ImageCropper extends React.Component {

  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.editorRef = undefined
    this.state = {
      zoom: 1,
      maxZoom:1,
      currentPosition: undefined
    }
  }

  componentDidMount() {
    if (this.props.positions.length) {
      this.calculateDimensions()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.positions.length && this.state.currentPosition !== prevState.currentPosition) {
      this.calculateMaxZoom()
      this.calculateDimensions()
    }

  }

  calculateDimensions = () => {
    const currentPosition = this.state.currentPosition || this.props.positions[0]
    const maxWidth = this.containerRef.current.offsetWidth - 50
    const targetWidth = parseInt(currentPosition.width)
    const targetHeight = parseInt(currentPosition.height)

    let editorWidth = targetWidth
    let editorHeight = targetHeight

    if (editorWidth > maxWidth) {
      editorWidth = maxWidth
      editorHeight = (editorHeight / editorWidth) * maxWidth
    }
    this.setState({maxWidth, editorWidth, editorHeight, targetWidth, targetHeight, currentPosition})
  }


    imageDimensionsOptions = this.props.positions.map((v) => {
      return {
        text: `${v.name} - ${v.width}x${v.height}`,
        value: v.id
      }
    })


    dimensionsChange = (ev, {value}) => {
      const position = this.props.positions.find(({id}) => id===value)
      this.setState({
        currentPosition: position
      })
    }

    cropImage = async () => {
      if (this.editorRef) {
        const canvas = this.editorRef.getImageScaledToCanvas().toDataURL()
        const { data } = await axios.get(canvas, {
          responseType: 'blob',
        })
        this.setState({
          image: undefined
        })
        this.props.onCrop(data)
      }
    }

    cancelCrop = () => {
      if (this.props.onCancel) {
        this.props.onCancel()
      }
    }

    setEditorRef = (editor) => this.editorRef = editor

    onZoomChange = ({target}) => {
      this.setState({
        zoom: parseFloat(target.value)
      })
    }

    blobToBase64 = (blob) => {

      return new Promise((resolve, reject) => {

        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {

          resolve(reader.result)

        }

      })

    }

    calculateMaxZoom = async () => {
      const img = new Image()
      img.onload = () => {

      if (img.width < this.state.targetWidth || img.height < this.state.targetHeight) {
        this.setState({
          error: 'too small'
        })
        if (this.props.onError) {

          this.props.onError('too small')

        }

      }
      else {
        this.setState({
          maxZoom: img.width > img.height ? img.width / this.state.targetWidth : img.height / this.state.targetHeight
        })

      }

    }
    img.src = await this.blobToBase64(this.props.image)
  }

  noPositionsView = () => (
    <div>
      No positions found. Please create some first.
      <Button type='button' secondary onClick={this.cancelCrop}>Cancel</Button>
    </div>
  )

    render() {

      if (this.props.positions.length === 0) {
        return this.noPositionsView()
      }

      return (
        <div style={{display: 'flex', flexDirection: 'column', width:'100%'}} ref={this.containerRef}>
          <b>Crop your image to one of the predefined dimensions</b>
          <Select onChange={this.dimensionsChange} defaultValue={this.props.positions[0].id} placeholder='Select dimensions' options={this.imageDimensionsOptions} />
          <AvatarEditor ref={this.setEditorRef} width={this.state.editorWidth} scale={this.state.zoom} height={this.state.editorHeight} image={this.props.image} />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <input style={{width: '100%'}} type="range" min={1} max={this.state.maxZoom} step={0.0001} value={this.state.zoom} onChange={this.onZoomChange} />
            <Button type='button' secondary onClick={this.cancelCrop}>Cancel</Button>
            <Button type='button' primary onClick={this.cropImage}>Crop</Button>
          </div>
        </div>
      )
    }
}

export default ImageCropper
