import { actionTypes } from '../actions/website'

export const defaultState = {
  isLoadingWebsites: false,
  hasWebsiteLoadFailed: false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_WEBSITES :
        return {
          ...state,
          isLoadingWebsites: true,
          hasWebsiteLoadFailed: false
        }
    case actionTypes.LOADING_WEBSITES_FAILED :
        return {
          ...state,
          isLoadingWebsites: false,
          hasWebsiteLoadFailed: true
        }
    case actionTypes.LOADING_WEBSITES_SUCCESS :
        return {
          ...state,
          isLoadingWebsites: false,
          hasWebsiteLoadFailed: false,
          hasSubmittingWebsiteSucceded: false,
          websites: action.payload
        }
    case actionTypes.SUBMITTING_WEBSITE :
        return {
          ...state,
          isSubmittingWebsite: true,
          hasSubmittingWebsiteSucceded: false
        }
    case actionTypes.SUBMITTING_WEBSITE_FAILED :
        return {
          ...state,
          isSubmittingWebsite: false,
          hasSubmittingWebsiteSucceded: false
        }
    case actionTypes.SUBMITTING_WEBSITE_SUCCESS :
        return {
          ...state,
          isSubmittingWebsite: false,
          hasSubmittingWebsiteSucceded: true
        }
    default:
        return state
  }
}
