import React from 'react'
import { Form, Dropdown } from 'semantic-ui-react'
import * as Yup from 'yup'
import ContentForm from './ContentForm'
import { debounce } from "debounce"
import ImageSelector from '../containers/ImageSelector'
import RichTextArea from './RichTextArea'

class WebsiteForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tagsSelected: new Map(),
      enabled: undefined,
      imagePositions: [],
      fetchUrl: undefined
    }
  }

  componentDidMount() {

    this.props.loadImagePositions()
    if (!this.props.categories) {
      this.props.loadCategories()
    }

    if (!this.props.tags) {
      this.props.loadTags()
    }

  }

  ImageSelectorWrapper = (props) => {
    return <ImageSelector
    {...props}
    onChange={(v) => {
      props.onChange(null, {value: v})
    }}
     />
  }


  loadCategories = (e, {searchQuery}) => {
    this.props.loadCategories({term: searchQuery})
  }

  getCategories = () => {
    let categories = []
    if (this.props.categories) {
      categories = this.props.categories.map((category) => {
        return {
          value: category.id,
          key: category.id,
          text: category.name
        }
      })

    }

    return categories
  }

  SelectCategoryComponent = (props) => (
    <Form.Field>
      <label>Category</label>
      <Dropdown
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        fluid
        search
        clearable
        selection
        selectOnBlur={false}
        placeholder="Start typing for a category"
        options={this.getCategories()}
        onSearchChange={debounce(this.loadCategories, 600)} />
    </Form.Field>
  )

  RichTextAreaWrapper = (props) => (
    <Form.Field>
      <label>{props.label}</label>
      <RichTextArea onChange={(value) => props.onChange(null, {value})} value={props.value} onBlur={props.onBlur}/>
    </Form.Field>
  )

  loadTags = (e, {searchQuery}) => {
    this.props.loadTags({term: searchQuery})
  }


  transformTags = (tags) => {
    let tagsList = new Map()
    tags.forEach((tag) => {
      tagsList.set(tag.id, {
        value: tag.id,
        key: tag.id,
        text: tag.name
      })
    })

    if (this.state.tagsSelected) {
      this.state.tagsSelected.forEach((tag) => {
        tagsList.set(tag.id, {
          value: tag.id,
          key: tag.id,
          text: tag.name
        })
      })
    }

    return [...Array.from(tagsList.values())]
  }




  handleTagsChange = (tagIdsSelected) => {
    let tagsSelected = new Map()
    this.state.tagsSelected.forEach((tag) => {
      if (tagIdsSelected.indexOf(tag.id) !== -1) {
        tagsSelected.set(tag.id, tag)
      }
    })
    if (this.props.tags) {
      this.props.tags.forEach((tag) => {
        if (tagIdsSelected.indexOf(tag.id) !== -1) {
          tagsSelected.set(tag.id, tag)
        }
      })
    }

    this.setState({tagsSelected})

  }

  SelectTagsComponent = (props) => {
    return(
    <Form.Field >
      <label>Tags</label>
      <Dropdown
        onChange={(e,v) => {
          this.handleTagsChange(v.value)
          props.onChange(e, v)
        }}
        onBlur={props.onBlur}
        value={props.value || []}
        fluid
        search
        multiple
        clearable
        selection
        selectOnBlur={false}
        placeholder="Start typing for a tag"
        options={this.transformTags(this.props.tags || [])}
        onSearchChange={debounce(this.loadTags, 600)} />
    </Form.Field>
  )}

  EnabledComponent = (props) => {
    let checked = false
    if (typeof this.state.enabled === 'undefined') {
      checked = this.props.editMode ? this.props.data.enabled : false
    } else {
      checked = this.state.enabled
    }
    return (
      <Form.Checkbox
        toggle
        checked={checked}
        onChange={(e,v) => {
          this.setState({
            enabled: v.checked
          })
          props.onChange(e, {...v, value: v.checked})}
        }
        label="Enabled"
        />
    )
  }

  UrlWrapper = (props) => {
    return <Form.Input
      {...props}
      onChange={(e,v) => {
        this.setState({
          fetchUrl: v.value
        })
        props.onChange(e, v)}
      }
    />
  }

  tagIdsToObjects = (tagIds) => {
    return tagIds.map((v) => {
      return this.props.tags.find((t) => v==t.id)
    })
  }

  render() {
    return (
      <ContentForm
      defaultData={{
        name: '',
        shortDescription: '',
        fullDescription: '',
        categoryId: null,
        tags: [],
        url: '',
        refUrl: '',
        enabled: false
      }}
      isSubmitting={this.props.isSubmitting}
      hasSucceded={this.props.hasSucceded}
      submit={(data) => { this.props.submit({...data, tags: data.tags.map((v) => this.props.tags.find((t) => v===t.id))}) }}
      edit={async (data) => {
        const tags = this.tagIdsToObjects(data.tags)
        this.props.edit({
          ...data,
          tags: tags})
      }}
      editMode={this.props.editMode}
      delete={this.props.delete}
      data={this.props.data ? {...this.props.data, tags: this.props.data.tags.map((v) => v.id)} : undefined}
      onSuccess={() => {
        this.props.onSuccess()
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Required')
      })}
      >
        <Form.Input
          name="name"
          placeholder="The name of the website"
          label="Name"
        />
        <this.RichTextAreaWrapper
          name="shortDescription"
          label="Short description"
        />
        <this.RichTextAreaWrapper
          name="fullDescription"
          label="Full description"
        />
        <this.UrlWrapper
          name="url"
          label="Url"
          placeholder="Base url of the website"
        />
        <Form.Input
          name="refUrl"
          placeholder="The referral url"
          label="Referral Url"
        />
        <this.SelectCategoryComponent name="categoryId" />
        <this.SelectTagsComponent name="tags" />
        <this.EnabledComponent name="enabled" />
        <this.ImageSelectorWrapper name="images" fetchUrl={this.state.fetchUrl} positions={this.props.imagePositions || []} />

      </ContentForm>
    )
  }
}


export default WebsiteForm
