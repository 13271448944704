import React from 'react'
import { Menu, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const MainMenu = (props) => {
  return (
    <Segment compact={true} basic={true}>
      <Menu pointing secondary vertical>
        <Menu.Item
          name="websites"
          as={Link}
          to="/admin/websites"
          active={props.current === 'websites'}
        />
        <Menu.Item
          name="categories"
          as={Link}
          to="/admin/categories"
          active={props.current === 'categories'}
        />
        <Menu.Item
          name="tags"
          as={Link}
          to="/admin/tags"
          active={props.current === 'tags'}
        />
      </Menu>
    </Segment>
  )
}

export default MainMenu
