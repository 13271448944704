import React from 'react'
import { Button, Form, Segment } from 'semantic-ui-react'
import { Formik } from 'formik'
import * as Yup from 'yup';
import ErrorSummary from './ErrorSummary'


const LoginForm = (props) => {

    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          username: '',
          password: ''
        }}
        validationSchema={
          Yup.object().shape({
            username: Yup.string()
              .required('Required'),
            password: Yup.string()
              .required('Required')
          })
        }
        onSubmit={values => props.login(values)}
      >
        {(formikProps) => (
            <Form error={Object.keys(formikProps.errors).length > 0 || props.hasLoginFailed} onSubmit={formikProps.handleSubmit} size='large'>
              <Segment textAlign='left'>
                <Form.Input
                  id='username'
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.username}
                  error={formikProps.errors.username && formikProps.touched.username}
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder='Username' />
                <Form.Input
                  id='password'
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.password}
                  error={formikProps.errors.password && formikProps.touched.password}
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                />
                <Button
                  color='teal'
                  fluid
                  size='large'
                  type='submit'
                  loading={props.isAuthenticating}
                >
                  Login
                </Button>
                <ErrorSummary errors={props.hasLoginFailed ? {...formikProps.errors, login: 'Failed to authenticate'} : formikProps.errors} />
              </Segment>
            </Form>
          )}
      </Formik>
    )
}

export default LoginForm
