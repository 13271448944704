import { connect } from 'react-redux'
import CategoriesPage from '../pages/CategoriesPage'
import { loadCategories, loadCategoryImagePositions } from '../actions/category'


const mapDispatchToProps = (dispatch) => ({
    loadCategories: (filterData) => dispatch(loadCategories(filterData)),
    loadImagePositions: (filterData) => dispatch(loadCategoryImagePositions(filterData))
  }
)

const mapStateToProps = (state) => ({
  categories: state.category.categories,
  imagePositions: state.categoryImagePosition.categoryImagePositions
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesPage)
