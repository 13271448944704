import { connect } from 'react-redux'
import TagForm from '../components/TagForm'
import { createTag, editTag, deleteTag } from '../actions/tag'
import { loadTagTypes } from '../actions/tagType'


const mapStateToProps = (state) => ({
  isSubmitting: state.tag.isSubmittingTag,
  hasSucceded: state.tag.hasSubmittingTagSucceded,
  tagTypes: state.tagType.tagTypes

})

const mapDispatchToProps = (dispatch, props) => ({
  submit: data => dispatch(createTag(data)),
  edit: data => dispatch(editTag(data)),
  delete: data => dispatch(deleteTag(data)),
  loadTagTypes: params => dispatch(loadTagTypes({...params}))


})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagForm)
