import { connect } from 'react-redux'
import ImagePool from './ImagePool'
import { loadImages } from './actions'

const mapStateToProps = (state) => ({
  isLoading: state.image.isLoadingImages,
  images: state.image.images
})

const mapDispatchToProps = (dispatch, props) => ({
  loadImages: data => dispatch(loadImages(data)),


})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagePool)
