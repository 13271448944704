import { connect } from 'react-redux'
import WebsitesPage from '../pages/WebsitesPage'
import { loadWebsites, loadWebsiteImagePositions } from '../actions/website'


const mapDispatchToProps = (dispatch) => {
  return {
    loadWebsites: (filterData) => dispatch(loadWebsites(filterData)),
    loadImagePositions: (filterData) => dispatch(loadWebsiteImagePositions(filterData))
  }
}

const mapStateToProps = (state) => ({
  websites: state.website.websites,
  imagePositions: state.websiteImagePosition.websiteImagePositions
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsitesPage)
