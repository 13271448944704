import React from 'react'
import { DragSource } from 'react-dnd';
import ImageDragTypes from './ImageDragTypes'

class Thumbnail extends React.Component {

  render() {
    return this.props.connectDragSource(
      <div style={{width:'100px',overflow: 'hidden',opacity:`${this.props.isDragging ? '0.4' : '1'}`}}>
        <img width={100} key={this.props.key} src={this.props.accessLocation} />
      </div>
    )
  }
}

const source = {
  canDrag(props) {
    // You can disallow drag based on props
    // return props.isReady;
    return true;
  },

  beginDrag(props, monitor, component) {
    // Return the data describing the dragged item
    return props
  }
};

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

export default DragSource(ImageDragTypes.THUMBNAIL, source, collect)(Thumbnail)
