import React from 'react'
import { Form, Dropdown } from 'semantic-ui-react'
import * as Yup from 'yup';
import ContentForm from './ContentForm'


class TagTypeForm extends React.Component {

  render() {
    return (
      <ContentForm
      defaultData={{
        name: ''
      }}
      {...this.props}
      onSuccess={() => {
        this.props.onSuccess()
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Required')
      })}
      >
        <Form.Input
          name="name"
          placeholder="The name of the tag"
          label="Name"
        />

      </ContentForm>
    )
  }
}


export default TagTypeForm
