import React from 'react'
import Framework from '../components/Framework'
import MainMenu from '../components/MainMenu'
import {  Button, Tab, Menu, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ModalWrapper from '../components/ModalWrapper'
import ContentList from '../components/ContentList'


export default class ContentPane extends React.Component {

  state = {
    modalOpen: false
  }

  componentDidMount() {
    this.props.onMount()
  }

  onCreateClick = () => {
    this.setState({
      modalOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  render() {
    return (<Tab.Pane>
      <ModalWrapper headerIcon='plus' headerContent='Create' onClose={this.closeModal} open={this.state.modalOpen} render={
        (close) => this.props.createForm && this.props.createForm(close)
      }/>
      <Button primary onClick={this.onCreateClick}>Create</Button>
      <ContentList
        entities={this.props.entities}
        onFilterChange={this.props.onFilterChange}
        editForm={(close, data) => this.props.editForm && this.props.editForm(close, data)}/>
    </Tab.Pane>)
  }
}
