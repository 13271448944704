import { connect } from 'react-redux'
import ImageSelector from '../components/ImageSelector/'
import { uploadImage, loadImages, clearImage } from '../components/ImageSelector/actions'

const mapStateToProps = (state) => ({
  isUploading: state.image.isUploading,
  hasUploadingSucceded: state.image.hasUploadingSucceded,
  images: state.image.images,
  fetchedImage: state.image.fetchedImage
})

const mapDispatchToProps = (dispatch, props) => ({
  uploadImage: data => dispatch(uploadImage(data)),
  loadImages: data => dispatch(loadImages(data)),
  clearImage: () => dispatch(clearImage())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageSelector)
