import axios from 'axios'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const actionTypes = {
  LOADING_IMAGES_FAILED: 'LOADING_IMAGES_FAILED',
  LOADING_IMAGES_SUCCESS: 'LOADING_IMAGES_SUCCESS',
  LOADING_IMAGES: 'LOADING_IMAGES',
  SUBMITTING_IMAGE: 'SUBMITTING_IMAGE',
  SUBMITTING_IMAGE_FAILED: 'SUBMITTING_IMAGE_FAILED',
  SUBMITTING_IMAGE_SUCCESS: 'SUBMITTING_IMAGE_SUCCESS',
  UPLOADING_IMAGE: 'UPLOADING_IMAGE',
  UPLOADING_IMAGE_FAILED: 'UPLOADING_IMAGE_FAILED',
  UPLOADING_IMAGE_SUCCESS: 'UPLOADING_IMAGE_SUCCESS',
  FETCHING: 'FETCHING',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_FAILED: 'FETCHING_FAILED',
  FETCHED_IMAGE_CLEARED: 'FETCHED_IMAGE_CLEARED'
}

export const submittingImageSuccess = (data) => {
  return {
      type: actionTypes.SUBMITTING_IMAGE_SUCCESS,
      payload: data
  }
}

export const submittingImageFailed = () => {
  return {
      type: actionTypes.SUBMITTING_IMAGE_FAILED
  }
}

export const submittingImage = () => {
  return {
      type: actionTypes.SUBMITTING_IMAGE
  }
}

export const uploadingImageSuccess = (data) => {
  return {
      type: actionTypes.UPLOADING_IMAGE_SUCCESS,
      payload: data
  }
}

export const uploadingImageFailed = () => {
  return {
      type: actionTypes.UPLOADING_IMAGE_FAILED
  }
}

export const uploadingImage = () => {
  return {
      type: actionTypes.UPLOADING_IMAGE
  }
}

export const loadImagesSuccess = (data) => {
  return {
      type: actionTypes.LOADING_IMAGES_SUCCESS,
      payload: data
  }
}

export const loadImagesFailed = () => {
  return {
      type: actionTypes.LOADING_IMAGES_FAILED
  }
}

export const loadingImages = () => {
  return {
      type: actionTypes.LOADING_IMAGES
  }
}

export const clearImage = () => {
  return {
      type: actionTypes.FETCHED_IMAGE_CLEARED
  }
}

export const uploadImage = (image) => {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append('file', image, image.name);
      dispatch((uploadingImage()))
      const response = await axios.post('/api/images', data, {
        headers: {
          'x-access-token': cookies.get('tk'),
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      })
      dispatch(uploadingImageSuccess(response.data.image))
      dispatch(clearImage())
    } catch (e) {
      dispatch(uploadingImageFailed())
    }
  }
}

export const deleteImage = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingImage()))
      const response = await axios.delete(`/api/images/${params.id}`, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingImageSuccess(response.data.images))
    } catch (e) {
        dispatch(submittingImageFailed())
    }
  }
}

export const loadImages = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((loadingImages()))
      const response = await axios.get('/api/images', {
        params,
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(loadImagesSuccess(response.data.images))
    } catch (e) {
        dispatch(loadImagesFailed())
    }
  }
}


export const fetching = () => {
  return {
      type: actionTypes.FETCHING
  }
}

export const fetchingSuccess = (data) => {
  return {
      type: actionTypes.FETCHING_SUCCESS,
      payload: data
  }
}

export const fetchingFailed = () => {
  return {
    type: actionTypes.FETCHING_FAILED
  }
}


export const fetchImageOg = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((fetching()))
      const {data} = await axios.get('/api/images/get-og', {
        params,
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(fetchingSuccess(data.image))
    } catch (e) {
        dispatch(fetchingFailed())
    }
  }
}

export const fetchImageScr = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((fetching()))
      const {data} = await axios.get('/api/images/get-scr', {
        params,
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(fetchingSuccess(data.image))
    } catch (e) {
        dispatch(fetchingFailed())
    }
  }
}
