import axios from 'axios'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const actionTypes = {
  LOADING_TAGS_FAILED: 'LOADING_TAGS_FAILED',
  LOADING_TAGS_SUCCESS: 'LOADING_TAGS_SUCCESS',
  LOADING_TAGS: 'LOADING_TAGS',
  SUBMITTING_TAG: 'SUBMITTING_TAG',
  SUBMITTING_TAG_FAILED: 'SUBMITTING_TAG_FAILED',
  SUBMITTING_TAG_SUCCESS: 'SUBMITTING_TAG_SUCCESS'
}

export const submittingTagSuccess = (data) => {
  return {
      type: actionTypes.SUBMITTING_TAG_SUCCESS,
      payload: data
  }
}

export const submittingTagFailed = () => {
  return {
      type: actionTypes.SUBMITTING_TAG_FAILED
  }
}

export const submittingTag = () => {
  return {
      type: actionTypes.SUBMITTING_TAG
  }
}

export const createTag = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingTag()))
      const response = await axios.post('/api/tags', params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingTagSuccess(response.data.tags))
    } catch (e) {
        dispatch(submittingTagFailed())
    }
  }
}

export const deleteTag = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingTag()))
      const response = await axios.delete(`/api/tags/${params.id}`, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingTagSuccess(response.data.tags))
    } catch (e) {
        dispatch(submittingTagFailed())
    }
  }
}

export const editTag = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingTag()))
      console.log(params)
      const response = await axios.put(`/api/tags/${params.id}`, params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingTagSuccess(response.data.tags))
    } catch (e) {
        dispatch(submittingTagFailed())
    }
  }
}

export const loadTagsSuccess = (data) => {
  return {
      type: actionTypes.LOADING_TAGS_SUCCESS,
      payload: data
  }
}

export const loadingTagsFailed = () => {
  return {
      type: actionTypes.LOADING_TAGS_FAILED
  }
}

export const loadingTags = () => {
  return {
      type: actionTypes.LOADING_TAGS
  }
}

export const loadTags = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((loadingTags()))
      const response = await axios.get('/api/tags', {params})
      dispatch(loadTagsSuccess(response.data.tags))
    } catch (e) {
        dispatch(loadingTagsFailed())
    }
  }
}
