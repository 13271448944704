import axios from 'axios'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const actionTypes = {
  LOADING_TAG_TYPES_FAILED: 'LOADING_TAG_TYPES_FAILED',
  LOADING_TAG_TYPES_SUCCESS: 'LOADING_TAG_TYPES_SUCCESS',
  LOADING_TAG_TYPES: 'LOADING_TAG_TYPES',
  SUBMITTING_TAG_TYPE: 'SUBMITTING_TAG_TYPE',
  SUBMITTING_TAG_TYPE_FAILED: 'SUBMITTING_TAG_TYPE_FAILED',
  SUBMITTING_TAG_TYPE_SUCCESS: 'SUBMITTING_TAG_TYPE_SUCCESS'
}

export const submittingTagTypeSuccess = (data) => {
  return {
      type: actionTypes.SUBMITTING_TAG_TYPE_SUCCESS,
      payload: data
  }
}

export const submittingTagTypeFailed = () => {
  return {
      type: actionTypes.SUBMITTING_TAG_TYPE_FAILED
  }
}

export const submittingTagType = () => {
  return {
      type: actionTypes.SUBMITTING_TAG_TYPE
  }
}

export const createTagType = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingTagType()))
      const response = await axios.post('/api/tagTypes', params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingTagTypeSuccess(response.data.tagTypes))
    } catch (e) {
        dispatch(submittingTagTypeFailed())
    }
  }
}

export const deleteTagType = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingTagType()))
      const response = await axios.delete(`/api/tagTypes/${params.id}`, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingTagTypeSuccess(response.data.tagTypes))
    } catch (e) {
        dispatch(submittingTagTypeFailed())
    }
  }
}

export const editTagType = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingTagType()))
      const response = await axios.put(`/api/tagTypes/${params.id}`, params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingTagTypeSuccess(response.data.tagTypes))
    } catch (e) {
        dispatch(submittingTagTypeFailed())
    }
  }
}

export const loadTagTypesSuccess = (data) => {
  return {
      type: actionTypes.LOADING_TAG_TYPES_SUCCESS,
      payload: data
  }
}

export const loadingTagTypesFailed = () => {
  return {
      type: actionTypes.LOADING_TAG_TYPES_FAILED
  }
}

export const loadingTagTypes = () => {
  return {
      type: actionTypes.LOADING_TAG_TYPES
  }
}

export const loadTagTypes = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((loadingTagTypes()))
      const response = await axios.get('/api/tagTypes', {params})
      dispatch(loadTagTypesSuccess(response.data.tagTypes))
    } catch (e) {
        dispatch(loadingTagTypesFailed())
    }
  }
}
