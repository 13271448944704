import React from 'react'
import Framework from '../components/Framework'
import MainMenu from '../components/MainMenu'
import TagTypesList from '../containers/TagTypesList'
import { Button } from 'semantic-ui-react'
import ModalWrapper from '../components/ModalWrapper'
import TagTypeForm from '../containers/TagTypeForm'


class TagTypesPage extends React.Component {
  state = {
    modalOpen: false,
    filterData: undefined
  }

  onCreateClick = () => {
    this.setState({
      modalOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  onFilterChange = (filterData) => {
    this.setState({
      filterData
    })
  }

  render() {
    return (
      <Framework>
        <div style={{
        display: 'flex'
        }}>
          <div>
            <MainMenu current='tags' />
          </div>
          <div>
            <ModalWrapper headerIcon='plus' headerContent='Create tag type' onClose={this.closeModal} open={this.state.modalOpen} render={
              (close) => (
                <TagTypeForm onSuccess={() => {
                  this.closeModal()
                  this.props.loadTagTypes(this.state.filterData)
                }}
                />
              )
            }/>
            <Button primary onClick={this.onCreateClick}>Create</Button>
            <TagTypesList onFilterChange={this.onFilterChange} />
          </div>
        </div>
      </Framework>
      )
  }
}

export default TagTypesPage
