import { connect } from 'react-redux'
import LoginForm from '../components/LoginForm'
import { login } from '../actions/login'

const mapStateToProps = (state) => ({
  isAuthenticating: state.login.isAuthenticating,
  hasLoginFailed: state.login.hasLoginFailed
})

const mapDispatchToProps = (dispatch, props) => ({
  login: data => dispatch(login(data, props.cookies))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)
