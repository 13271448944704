import React from 'react'
import Thumbnail from './Thumbnail'

class ImagePool extends React.Component {

  componentDidMount() {
    this.props.loadImages({
      limit: 6
    })
  }

  render() {
    return (
          <div style={{display: 'flex', flexDirection: 'row', flexWrap:'wrap',justifyContent:'space-evenly'}}>
          {this.props.images && this.props.images.map((image, key) => {
            return <div key={`thumb-${key}-container`} style={{marginBottom:'10px'}}><Thumbnail key={`thumb-${key}`} {...image}/></div>
          })}
          </div>
    )
  }
}
export default ImagePool
