import React from 'react'
import Thumbnail from './Thumbnail'
import { DropTarget } from 'react-dnd'
import ImageDragTypes from './ImageDragTypes'
import { Icon } from 'semantic-ui-react'
import uuidv1 from 'uuid/v1'

class ImagePosition extends React.Component {


  render() {
    const items = Array.from(this.props.items.entries())
    return this.props.connectDropTarget(
      <div style={{width:'100%', height:'150px', border: `4px dotted ${this.props.hovered ? 'red' : '#e0e0e0'}`}}>
        <b>{`${this.props.name}`}</b>
        <div style={{display: 'flex'}}>
          {items.map(([k,v]) => (
            <div key={k} style={{position: 'relative'}}>
            <Icon style={{position: 'absolute', cursor: 'pointer', top: '5px', right: '5px'}} onClick={ () => this.props.onItemRemoved(k)} name='remove circle'></Icon>
            <Thumbnail {...v} />
            </div>
          ))}
        </div>
      </div>
    )
  }

}

const spec = {
    drop: (props, monitor, component) => {
      const item = monitor.getItem();
      props.onItemDropped(item)

    },
    hover: (props, monitor, component) => {
    }
  }

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hovered: monitor.isOver()
})


const ImagePositionTarget =  DropTarget(ImageDragTypes.THUMBNAIL, spec, collect)(ImagePosition)

export default class ImagePositionContainer extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      items: new Map()
    }
  }

  componentDidMount() {
    if (this.props.value) {
      const items = new Map()
      for ( const item of this.props.value ) {
        const uid = uuidv1()
        items.set(uid, item)
      }
      this.setState({
        items
      })
    }
  }

  onItemDropped = (item) => {
      const uid = uuidv1()
      const newItems = new Map(this.state.items)
      newItems.set(uid, item)
      this.setState({
        items: newItems
      })
      if (this.props.onChange) {
        this.props.onChange(Array.from(newItems.values()))
      }

  }

  onItemRemoved = (id) => {
    const newItems = new Map(this.state.items)
    newItems.delete(id)
    this.setState({
      items: newItems
    })
    if (this.props.onChange) {
      this.props.onChange(Array.from(newItems.values()))
    }
  }

  render() {
    return <ImagePositionTarget items={this.state.items} onItemRemoved={this.onItemRemoved} onItemDropped={this.onItemDropped} name={this.props.name} limit={this.props.limit}/>
  }
}
