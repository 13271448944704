import { actionTypes } from '../actions/login'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const defaultState = {
  isAuthenticating: false,
  isLoggedIn: cookies.get('tk') ? true : false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST :
        return {
          isAuthenticating: true,
          isLoggedIn: false,
          hasLoginFailed: false
        }
    case actionTypes.LOGIN_SUCCESS :
        return {
          isAuthenticating: false,
          isLoggedIn: true,
          token: action.payload,
          hasLoginFailed: false
        }
    case actionTypes.LOGIN_FAILED :
        return {
          isAuthenticating: false,
          isLoggedIn: false,
          hasLoginFailed: true
        }
    default:
        return state
  }
}
