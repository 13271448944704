import { connect } from 'react-redux'
import TagsList from '../components/TagsList'
import { loadTags } from '../actions/tag'

const mapStateToProps = (state) => ({
  loading: state.tag.isLoadingTag,
  loadingFailed: state.tag.hasTagLoadFailed,
  tags: state.tag.tags
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadTags: (params) => dispatch(loadTags(params))
  }
}



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsList)
