import { actionTypes } from '../actions/website'

export const defaultState = {
  isLoadingWebsiteImagePositions: false,
  hasWebsiteImagePositionLoadFailed: false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_WEBSITE_IMAGE_POSITIONS :
        return {
          ...state,
          isLoadingWebsiteImagePositions: true,
          hasWebsiteImagePositionLoadFailed: false
        }
    case actionTypes.LOADING_WEBSITE_IMAGE_POSITIONS_FAILED :
        return {
          ...state,
          isLoadingWebsiteImagePositions: false,
          hasWebsiteImagePositionLoadFailed: true
        }
    case actionTypes.LOADING_WEBSITE_IMAGE_POSITIONS_SUCCESS :
        return {
          ...state,
          isLoadingWebsiteImagePositions: false,
          hasWebsiteImagePositionLoadFailed: false,
          hasSubmittingWebsiteImagePositionSucceded: false,
          websiteImagePositions: action.payload
        }
    case actionTypes.SUBMITTING_WEBSITE_IMAGE_POSITION :
        return {
          ...state,
          isSubmittingWebsiteImagePosition: true,
          hasSubmittingWebsiteImagePositionSucceded: false
        }
    case actionTypes.SUBMITTING_WEBSITE_IMAGE_POSITION_FAILED :
        return {
          ...state,
          isSubmittingWebsiteImagePosition: false,
          hasSubmittingWebsiteImagePositionSucceded: false
        }
    case actionTypes.SUBMITTING_WEBSITE_IMAGE_POSITION_SUCCESS :
        return {
          ...state,
          isSubmittingWebsiteImagePosition: false,
          hasSubmittingWebsiteImagePositionSucceded: true
        }
    default:
        return state
  }
}
