import axios from 'axios'
import Cookies from 'universal-cookie';
import { imageDataToMap, imageDataToArray } from '../lib/imageDataConverter'

const cookies = new Cookies();

export const actionTypes = {
  LOADING_CATEGORIES_FAILED: 'LOADING_CATEGORIES_FAILED',
  LOADING_CATEGORIES_SUCCESS: 'LOADING_CATEGORIES_SUCCESS',
  LOADING_CATEGORIES: 'LOADING_CATEGORIES',
  SUBMITTING_CATEGORY: 'SUBMITTING_CATEGORY',
  SUBMITTING_CATEGORY_FAILED: 'SUBMITTING_CATEGORY_FAILED',
  SUBMITTING_CATEGORY_SUCCESS: 'SUBMITTING_CATEGORY_SUCCESS',
  SUBMITTING_CATEGORY_IMAGE_POSITION: 'SUBMITTING_CATEGORY_IMAGE_POSITION',
  SUBMITTING_CATEGORY_IMAGE_POSITION_SUCCESS: 'SUBMITTING_CATEGORY_IMAGE_POSITION_SUCCESS',
  SUBMITTING_CATEGORY_IMAGE_POSITION_FAILED: 'SUBMITTING_CATEGORY_IMAGE_POSITION_FAILED',
  LOADING_CATEGORY_IMAGE_POSITIONS: 'LOADING_CATEGORY_IMAGE_POSITIONS',
  LOADING_CATEGORY_IMAGE_POSITIONS_SUCCESS: 'LOADING_CATEGORY_IMAGE_POSITIONS_SUCCESS',
  LOADING_CATEGORY_IMAGE_POSITIONS_FAILED: 'LOADING_CATEGORY_IMAGE_POSITIONS_FAILED'
}

export const submittingCategorySuccess = (data) => {
  return {
      type: actionTypes.SUBMITTING_CATEGORY_SUCCESS,
      payload: data
  }
}

export const submittingCategoryFailed = () => {
  return {
      type: actionTypes.SUBMITTING_CATEGORY_FAILED
  }
}

export const submittingCategory = () => {
  return {
      type: actionTypes.SUBMITTING_CATEGORY
  }
}

export const createCategory = (params = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch((submittingCategory()))
      const {categoryImagePosition} = getState()
      const data = {...params, images: imageDataToArray(params.images, categoryImagePosition.categoryImagePositions)}
      const response = await axios.post('/api/categories', data, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingCategorySuccess(response.data.categories))
    } catch (e) {
        dispatch(submittingCategoryFailed())
    }
  }
}

export const deleteCategory = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingCategory()))
      const response = await axios.delete(`/api/categories/${params.id}`, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingCategorySuccess(response.data.categories))
    } catch (e) {
        dispatch(submittingCategoryFailed())
    }
  }
}

export const editCategory = (params = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch((submittingCategory()))
      const {categoryImagePosition} = getState()
      const data = {...params, images: imageDataToArray(params.images, categoryImagePosition.categoryImagePositions)}
      const response = await axios.put(`/api/categories/${params.id}`, data, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingCategorySuccess(response.data.categories))
    } catch (e) {
        console.log(e)
        dispatch(submittingCategoryFailed())
    }
  }
}

export const loadCategoriesSuccess = (data) => {
  return {
      type: actionTypes.LOADING_CATEGORIES_SUCCESS,
      payload: data
  }
}

export const loadingCategoriesFailed = () => {
  return {
      type: actionTypes.LOADING_CATEGORIES_FAILED
  }
}

export const loadingCategories = () => {
  return {
      type: actionTypes.LOADING_CATEGORIES
  }
}

export const loadCategories = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((loadingCategories()))
      const { data } = await axios.get('/api/categories', {params})
      const transformedCategories = data.categories.map((category) => {
        return {...category, images: imageDataToMap(category.images)}
      })
      dispatch(loadCategoriesSuccess(transformedCategories))
    } catch (e) {
        dispatch(loadingCategoriesFailed())
    }
  }
}

export const loadCategoryImagePositionsSuccess = (data) => {
  return {
      type: actionTypes.LOADING_CATEGORY_IMAGE_POSITIONS_SUCCESS,
      payload: data
  }
}

export const loadingCategoryImagePositionsFailed = () => {
  return {
      type: actionTypes.LOADING_CATEGORY_IMAGE_POSITIONS_FAILED
  }
}

export const loadingCategoryImagePositions = () => {
  return {
      type: actionTypes.LOADING_CATEGORY_IMAGE_POSITIONS
  }
}

export const submittingCategoryImagePositionSuccess = (data) => {
  return {
      type: actionTypes.SUBMITTING_CATEGORY_IMAGE_POSITION_SUCCESS,
      payload: data
  }
}

export const submittingCategoryImagePositionFailed = () => {
  return {
      type: actionTypes.SUBMITTING_CATEGORY_IMAGE_POSITION_FAILED
  }
}

export const submittingCategoryImagePosition = () => {
  return {
      type: actionTypes.SUBMITTING_CATEGORY_IMAGE_POSITION
  }
}


export const loadCategoryImagePositions = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((loadingCategoryImagePositions()))
      const response = await axios.get('/api/categoryImagePositions', {params})
      dispatch(loadCategoryImagePositionsSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(loadingCategoryImagePositionsFailed())
    }
  }
}

export const createCategoryImagePosition = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingCategoryImagePosition()))
      const response = await axios.post('/api/categoryImagePositions', params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingCategoryImagePositionSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(submittingCategoryImagePositionFailed())
    }
  }
}

export const deleteCategoryImagePosition = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingCategoryImagePosition()))
      const response = await axios.delete(`/api/categoryImagePositions/${params.id}`, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingCategoryImagePositionSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(submittingCategoryImagePositionFailed())
    }
  }
}

export const editCategoryImagePosition = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch((submittingCategoryImagePosition()))
      const response = await axios.put(`/api/categoryImagePositions/${params.id}`, params, {
        headers: {
          'x-access-token': cookies.get('tk')
        }
      })
      dispatch(submittingCategoryImagePositionSuccess(response.data.imagePositions))
    } catch (e) {
        dispatch(submittingCategoryImagePositionFailed())
    }
  }
}
