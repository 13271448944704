import { connect } from 'react-redux'
import CategoryForm from '../components/CategoryForm'
import { createCategory, editCategory, deleteCategory, loadCategoryImagePositions } from '../actions/category'

const mapStateToProps = (state) => ({
  isSubmitting: state.category.isSubmittingCategory,
  hasSucceded: state.category.hasSubmittingCategorySucceded,
  imagePositions: state.categoryImagePosition.categoryImagePositions
})

const mapDispatchToProps = (dispatch, props) => ({
  submit: data => dispatch(createCategory(data)),
  edit: data => dispatch(editCategory(data)),
  delete: data => dispatch(deleteCategory(data)),
  loadImagePositions: data => dispatch(loadCategoryImagePositions(data))

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryForm)
