import { connect } from 'react-redux'
import TagTypeForm from '../components/TagTypeForm'
import { createTagType, editTagType, deleteTagType } from '../actions/tagType'
import { loadTagTypes } from '../actions/tagType'


const mapStateToProps = (state) => ({
  isSubmitting: state.tagType.isSubmittingTagType,
  hasSucceded: state.tagType.hasSubmittingTagTypeSucceded

})

const mapDispatchToProps = (dispatch, props) => ({
  submit: data => dispatch(createTagType(data)),
  edit: data => dispatch(editTagType(data)),
  delete: data => dispatch(deleteTagType(data))

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagTypeForm)
