import React from 'react'
import { Header, Icon, Segment} from 'semantic-ui-react'


const Framework = (props) => {
    return (
      <Segment>
      <Header as='h1'>
        <Icon name='server' />
        <Header.Content>
          Control Panel
          <Header.Subheader>Add, edit or remove content</Header.Subheader>
        </Header.Content>
      </Header>
      {props.children}
      </Segment>
    )
}

export default Framework
