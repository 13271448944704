import { actionTypes } from '../actions/tagType'

export const defaultState = {
  isLoadingTagTypes: false,
  hasTagTypeLoadFailed: false

}

export const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_TAG_TYPES :
        return {
          ...state,
          isLoadingTagTypes: true,
          hasTagTypeLoadFailed: false
        }
    case actionTypes.LOADING_TAG_TYPES_FAILED :
        return {
          ...state,
          isLoadingTagTypes: false,
          hasTagTypeLoadFailed: true
        }
    case actionTypes.LOADING_TAG_TYPES_SUCCESS :
        return {
          ...state,
          isLoadingTagTypes: false,
          hasTagTypeLoadFailed: false,
          hasSubmittingTagTypeSucceded: false,
          tagTypes: action.payload
        }
    case actionTypes.SUBMITTING_TAG_TYPE :
        return {
          ...state,
          isSubmittingTagType: true,
          hasSubmittingTagTypeSucceded: false
        }
    case actionTypes.SUBMITTING_TAG_TYPE_FAILED :
        return {
          ...state,
          isSubmittingTagType: false,
          hasSubmittingTagTypeSucceded: false
        }
    case actionTypes.SUBMITTING_TAG_TYPE_SUCCESS :
        return {
          ...state,
          isSubmittingTagType: false,
          hasSubmittingTagTypeSucceded: true
        }
    default:
        return state
  }
}
