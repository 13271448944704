import React from 'react'
import { Button, Form, Icon } from 'semantic-ui-react'

class ImageFetcher extends React.Component {

  state = {
    url: undefined
  }

  fetchImageScr = () => {
    const url = this.state.url || this.props.url
    if (url) {
      this.props.fetchImageScr({url})
    }
  }

  fetchImageOg = () => {
    const url = this.state.url || this.props.url
    if (url) {
      this.props.fetchImageOg({url})
    }
  }

  onUrlChange = (e, {value}) => {
    this.setState({
      url: value
    })
  }

  render() {
    return (
      <div style={{width: '50%'}}>
        <Form.Input
          value={this.state.url || this.props.url || ''}
          placeholder="Url to fetch images from"
          label="Fetch from url"
          onChange={this.onUrlChange}
          disabled={this.props.isLoading}
        />
        <Button disabled={this.props.isLoading} onClick={this.fetchImageOg} type="button">{this.props.isLoading ? <Icon loading name='spinner' /> : 'Fetch og'}</Button>
        <Button disabled={this.props.isLoading} onClick={this.fetchImageScr} type="button">{this.props.isLoading ? <Icon loading name='spinner' /> : 'Fetch scr'}</Button>
      </div>
    )
  }
}

export default ImageFetcher
